export default {
  devMode: true,
  devDelay: 500,
  siteName: 'Hand Picked by Ruby | STAGING',
  timezoneOffset: '-6',
  message: 'superman is a cool dude',
  siteMeta: [
    { name: 'description', content: 'Personalized handmade fabric dolls to match your child' }
  ],
  API_PROTOCOL: 'https',
  API_URL: 'api-staging.dqt2.com'
};

