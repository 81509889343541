import React from 'react';

const ListItem = ({ idx, item, data, classes, onSelect }) => {

  const handleSelect = e => onSelect( data, idx );

  return (
    <div onClick={handleSelect} className={classes}>
      {item}
    </div>
  );
}

ListItem.propTypes = {
  item: React.PropTypes.any,
  classes: React.PropTypes.string
};

export default ListItem;