import React from 'react';
import NotFound from './modules/common/partials/notfound.view';
import Page from './modules/common/partials/page.view';
import OldShop from './modules/shop/shop.index';
import Shop from './modules/shop/shop.refactor';
import ShopHeader from './modules/shop/shop.header';
import ShopFooter from './modules/shop/shop.footer';
import Dolls from './modules/dolls/dolls.index';
import Test from './modules/test/test.index';
import About from './modules/about/about.view';
import Customizer from './modules/customizer/customizer.view';
import CustomizerNavigator from './modules/customizer/customizer.navigator';
import CustomizerDolls from './modules/customizer/customizer.dolls';
import ReduxExample from './modules/test/test.redux.form';
import Yolo from './modules/common/services/yolo.service';
import AuthService from './modules/common/services/auth.service';
import LoginRedirectService from './modules/common/services/login.redirect.service';
import LoginView from './modules/common/partials/login.view';
import { Route, IndexRoute, Redirect } from 'react-router';

const routes = (
  <Route component={ LoginRedirectService }>
    <Route path="/" component={ Page }>
      <IndexRoute components={{ header: ShopHeader, content: Shop, footer: ShopFooter }}></IndexRoute>
      <Route path="test" components={{ content: Test }}/>
      <Route path="about" components={{ content: About }}/>
      <Route path="dolls" components={{ content: Dolls }}/>
      <Route path="oldshop" components={{ content: OldShop }} />
      <Route component={ AuthService }>
        <Route path="yolo" components={{ content: Yolo }} />
      </Route>
      <Route path="customizer/:id" components={{ header: CustomizerNavigator, content: Customizer, footer: CustomizerDolls }} />
    </Route>
    <Route component={ AuthService }>
      <Route path="example" component={ ReduxExample } /> 
    </Route>
    <Route path="*" component={ NotFound } /> 
  </Route>
);

export default routes;