import React, { Component } from 'react';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { isNil } from 'mightty';

class CustomizerNavigator extends Component {

  constructor ( props ) {
    super ( props );
  }

  render() {
    let { dolls, params, wizard, location } = this.props,
    doll = dolls[params.id],
    progress = { width: `${doll.wizard.progress}%` };
    console.log( 'my location information is: ', location );
    return (
      <header id="progress">
        <div className="content-block">
          <div id="meter-progress">
            <div className="meter">
              <span style={progress}></span>
            </div>
          </div>
          <div className="m-nav-wrap">
            <div id="steps">
              <span id="current">{doll.wizard.step}</span>
              <span id="divider"> / </span>
              <span id="total">{doll.wizard.totalSteps}</span>
            </div>
            <a href="#" id="go-next" className="next-arrow">
              <img className="right-arrow" src="/dist/assets/images/ios-right-arrow.svg" />
            </a>
          </div>
          <h4 id="step-label" className="centered vertical-align no-margin">{doll.wizard.title}</h4>
        </div>
      </header>
    );
  };
};

const mapStateToProps = ( state ) => {
  return {
    dolls: state.order.dolls.items
  };
};

const mapDispatchToProps = ( dispatch ) => {
  return {
    changeRoute: ( url ) => dispatch( push( url ) )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( CustomizerNavigator );