import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { isNil } from 'mightty';
import { goGetDolls } from './dolls.actions';
import { addDoll } from '../order/order.actions';
import { trySignup } from '../login/login.actions';
import { loadSettings } from '../settings/settings.actions';
import List from '../common/components/list.component';
import ListItem from '../common/components/list.item.component';
import DollListItem from './doll.list.item';
import Logo from '../common/components/logo';
import LoadingIndicator from '../common/components/loading';
import H1 from '../common/components/h1';

class Dolls extends React.Component {

  // react only needs for class based components
  constructor ( props ) {
    super ( props );

    // setup local initial state defaults
    this.state = {
      submitted: false,
      userInput: ''
    }
    this.chooseDoll = this.chooseDoll.bind(this);
    this.ajax = this.ajax.bind(this);
  };

  componentDidMount () {
    this.props.getDolls();
  };

  /**
   * convenience function for route changes
   * @param {string} route - The route we want to go to
   */
  openRoute ( route ) {
    this.props.changeRoute( route );
  };

  ajax () {
    this.props.attemptSignup();
  };

  chooseDoll ( doll ) {
    console.log( 'i choose you:', doll );
    this.props.add( doll );
    let id = this.props.order.items.length;
    console.log( 'my current id: ', id);
    this.openRoute( `/customizer/${id}` );
  };

  render() {
    let mainContent = null;
    let { loading, dolls, error, settings, signupResponse } = this.props;
    // Show a loading indicator when we're loading
    if ( dolls.loading ) {
      mainContent = (<List component={LoadingIndicator} />);
    // Show an error if there is one
    } else if ( !isNil( dolls.error ) ) {
      const ErrorComponent = () => (
        <ListItem item={'Something went wrong, please try again!'} />
      );
      mainContent = (<List component={ErrorComponent} />);
    // If we're not loading, don't have an error and there are dolls, show the dolls
    } else if ( !isNil( dolls.items ) ) {
      mainContent = (<List items={dolls.items} component={DollListItem} onListItemSelection={this.chooseDoll} />);
    }

    return (
      <div className="row">
        <Helmet title="Dolls Page" />
        <div className="col-md-16">
          <Logo />
          <H1 text='Dolls' />
          {mainContent}
          <H1 text={settings.message} />
          <div className="btn btn-primary btn-lg" role="button" onClick={this.ajax}>Register</div>
        </div>
      </div>
    );
  };
};

// react prop validation
Dolls.propTypes = {
  dolls: React.PropTypes.object.isRequired,
  getDolls: React.PropTypes.func.isRequired,
  add: React.PropTypes.func.isRequired,
  changeRoute: React.PropTypes.func.isRequired
};

// redux convenience function, map redux global state to local props
const mapStateToProps = ( state ) => {
  console.log( 'tell me about the state now: ', state );
  return {
    dolls: state.dolls,
    order: state.order.dolls,
    settings: state.settings,
    signupResponse: state.login.response
  };
};

// redux convenience function, map redux global actions to local props
const mapDispatchToProps = ( dispatch ) => {
  return {
    getDolls: () => goGetDolls( dispatch ),
    add: ( doll ) => dispatch( addDoll( doll ) ),
    changeRoute: ( url ) => dispatch( push( url ) ),
    getSettings: () => dispatch( loadSettings() ),
    attemptSignup: () => dispatch( trySignup() )
  };
};

// redux function to attach mapStateToProps and mapDispatchToProps to Dolls module
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( Dolls );
