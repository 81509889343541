import React from 'react';

// stateless view component
const Login = ( props ) => {
  console.log('you are now in the login view', props);
  return (
    <div>
      { props.children }
    </div>
  );
};

export default Login;