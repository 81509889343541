import React from 'react';

const ChooseColors = () => {
  return (
    <div>
      <div className="row pad-5 gutter-10 top-row-pad">
        <div className="col-xs-16">
          <section className="header">
            <h3 className="centered">Hair</h3>
          </section>
        </div>
        <div className="row pad-8 gutter-10">
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container active">
              <input type="radio" id="black" name="hair" value="black" defaultChecked />
              <label htmlFor="black" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/hair/black-hair-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Black</h5>
                </div>
              </label>
            </div>
          </div>
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="brunette" name="hair" value="brunette" />
              <label htmlFor="brunette" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/hair/brown-hair-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Brunette</h5>
                </div>
              </label>
            </div>
          </div>
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="yellow" name="hair" value="yellow" />
              <label htmlFor="yellow" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/hair/yellow-blonde-hair-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Yellow Blonde</h5>
                </div>
              </label>
            </div>
          </div>
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="dirty-blonde" name="hair" value="dirty-blonde" />
              <label htmlFor="dirty-blonde" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/hair/dirty-blonde-hair-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Dirty Blonde</h5>
                </div>
              </label>
            </div>
          </div> 
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="red" name="hair" value="red" />
              <label htmlFor="red" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/hair/red-hair-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Red</h5>
                </div>
              </label>
            </div>
          </div>
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="white" name="hair" value="white" />
              <label htmlFor="white" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/hair/white-hair-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">White</h5>
                </div>
              </label>
            </div>
          </div>  
        </div>
      </div>
      <div className="row pad-5 gutter-10">
        <div className="col-xs-16">
          <section className="header">
            <h3 className="centered">Skin</h3>
          </section>
        </div>
        <div className="row pad-8 gutter-10">
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container active">
              <input type="radio" id="fair" name="skin" value="fair" defaultChecked />
              <label htmlFor="fair" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/skin/fair-skin-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Fair</h5>
                </div>
              </label>
            </div>
          </div>
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="tan" name="skin" value="tan" />
              <label htmlFor="tan" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/skin/tan-skin-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Tan</h5>
                </div>
              </label>
            </div>
          </div>
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="olive" name="skin" value="olive" />
              <label htmlFor="olive" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/skin/olive-skin-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Olive</h5>
                </div>
              </label>
            </div>
          </div>
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="dark" name="skin" value="dark" />
              <label htmlFor="dark" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/skin/dark-skin-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Dark</h5>
                </div>
              </label>
            </div>
          </div> 
        </div>
      </div>
      <div className="row pad-5 gutter-10 bottom-row-pad">
        <div className="col-xs-16">
          <section className="header">
            <h3 className="centered">Eyes</h3>
          </section>
        </div>
        <div className="row pad-8 gutter-10">
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container active">
              <input type="radio" id="blue" name="eyes" value="blue" defaultChecked />
              <label htmlFor="blue" className="swatch">
                <img className="thumb-sm" src="/dist/assets/images/swatches/eyes/blue-eye-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Blue</h5>
                </div>
              </label>
            </div>
          </div>
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="green" name="eyes" value="green" />
              <label htmlFor="green" className="swatch">
                <img className="thumb-sm eye-swatch" src="/dist/assets/images/swatches/eyes/green-eye-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Green</h5>
                </div>
              </label>
            </div>
          </div>
          <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
            <div className="swatch-container">
              <input type="radio" id="brown" name="eyes" value="brown" />
              <label htmlFor="brown" className="swatch">
                <img className="thumb-sm eye-swatch" src="/dist/assets/images/swatches/eyes/brown-eye-swatch-60px60px.jpg" />
                <div className="info">
                  <h5 className="no-margin">Brown</h5>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChooseColors;