import React from 'react';
const CartIcon = React.createClass({
  
  render: function() {
    let { svgId } = this.props;
    return (
      <svg viewBox="155 155 200 180" preserveAspectRatio="xMinYMin meet">
        <g id={ svgId }>
          <title>Cart</title>
          <description>Visual representation of a shopping cart</description>
          <path d="M231.17,333.67c-8.64,0-15.66-7-15.66-15.66c0-8.65,7.01-15.66,15.66-15.66s15.66,7.01,15.66,15.66
              C246.83,326.67,239.82,333.67,231.17,333.67z"/>
          <path d="M293.18,333.67c-8.65,0-15.66-7-15.66-15.66c0-8.65,7-15.66,15.66-15.66c8.64,0,15.65,7.01,15.65,15.66
              C308.83,326.67,301.82,333.67,293.18,333.67z"/>
          <path d="M338.58,217.73l-22.78,54.59c-2.39,6.34-9.57,11.32-16.35,11.32l-70.62,6c-8.08,0-15.8-6.18-17.57-14.07L191,197.92v-0.01
              c-0.83-3.67-5-7.02-8.75-7.02h-16.07c-15.18,0-3.19-11.57,0-11.57h16.06c26.26,0,28.59,22.85,28.59,22.85l115.77-1.74
              c4.69,0,8.7,1.89,10.98,5.19C339.87,208.92,340.23,213.34,338.58,217.73z"/>
        </g>
      </svg>
    );
  }

});

export default CartIcon;