import React from 'react';
import Helmet from 'react-helmet';
import { isNil } from 'mightty';
import { connect } from 'react-redux';
import List from './doll.list.js';
import { push } from 'react-router-redux';

const CustomizerDolls = ({ params, dolls, changeRoute }) => {

  const chooseDoll = ( data, idx ) => {
    changeRoute( `/customizer/${idx}` );
  };

  const addDoll = ( e ) => {
    e.preventDefault();
    setTimeout(() => {
      changeRoute( '/dolls' );
    }, 350 );
  };

  let selectedDoll = parseInt(params.id);
  return (
    <footer id="doll-list" className="container">
      <List items={dolls.items} selected={selectedDoll} onListItemClick={chooseDoll} addDoll={addDoll} />
    </footer>
  );
};

const mapStateToProps = ( state ) => {
  return {
    dolls: state.order.dolls
  };
};

const mapDispatchToProps = ( dispatch ) => {
  return {
    changeRoute: ( url ) => dispatch( push( url ) )
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( CustomizerDolls );