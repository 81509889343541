import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

const initialState = {
  loading: false,
  error: undefined,
  items: []
};

const makeImmutable = ( obj ) => fromJS( obj );

// immutable.equals compares value equality vs === which compares object identity equality
const loadDollsIfNeeded = ( previousState, currentState ) => makeImmutable( currentState ).equals( makeImmutable( previousState ) ) === false;

const reducers = {
  // return new array and copy state and action.payload
  // using es6 spread operator
  LOAD_DOLLS: ( state, action ) => ({ ...state, loading: true }),
  LOAD_DOLLS_SUCCESS: ( state, action ) => loadDollsIfNeeded( state.items, action.payload.items ) ? { ...state, items: action.payload.items, loading: false } : { ...state, loading: false },
  LOAD_DOLLS_ERROR: ( state, action ) => ({ ...state, error: action.payload.error })
};

const dollsReducers = handleActions( reducers, initialState );

export {
  dollsReducers
};