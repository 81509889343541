import React from 'react';
import ListItem from './doll.list.item';

const List = ({ items, selected, onListItemClick, addDoll }) => {
  return (
    <div className="row pad-5 gutter-10">
      { items.map((item, index) => {
          return <ListItem key={index} idx={index} item={item} active={selected} clicked={onListItemClick} />
        }) 
      }
      <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
        <div onClick={addDoll} className="doll-container last">
          <div id="add-doll">
            <div className="add-icon absolute-center">
              <i className="fa fa-plus fa-lg"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

List.propTypes = {
  items: React.PropTypes.array.isRequired,
  onListItemClick: React.PropTypes.func.isRequired
};

export default List;