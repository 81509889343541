import React from 'react';
import Helmet from 'react-helmet';
import { isNil } from 'mightty';
import ReactTransitionGroup from 'react-addons-transition-group';
import { connect } from 'react-redux';
import { toggleLoginModal } from '../../login/login.actions';
import LoginModal from '../components/login.modal';
import { addClass, removeClass } from '../utils/dom.utils';

const Page = React.createClass({ 

  _routerWillLeave( nextLocation ) {
    console.log( 'going to the nextLocation - from page.view', nextLocation );
    return false;
  },

  componentDidMount() {
    //this.context.router.setRouteLeaveHook( this.props.route, this._routerWillLeave );
  },

  componentWillReceiveProps( nextProps ) {
    const isLoggingIn = !this.props.user.authenticated && nextProps.user.authenticated;
    console.log( 'tell me about isLoggingIn', isLoggingIn );
    if ( isLoggingIn ) {
      this.context.router.replace({ 
        pathname: this.props.requestedPath
      });
    }
  },

  _modalLock() {
    addClass( document.documentElement,'lock-for-modal' );
    addClass( document.body,'lock-for-modal' );
  },

  _modalUnlock() {
    removeClass( document.documentElement,'lock-for-modal' );
    removeClass( document.body,'lock-for-modal' );
  },

  _getModal() {
    let modal = undefined;
    console.log( 'login modal active?!', this.props.loginModalActive );
    if ( this.props.loginModalActive ) {
      this._modalLock();
      modal = (<LoginModal key="app-login-modal-01" stateKey="login" />);
    } else {
      this._modalUnlock();
      modal = (<div></div>);
    }
    return modal;
  },

  render() {
    let { header, content, footer, settings, location } = this.props;
    let title = '%s | ' + settings.siteName,
    defaultTitle = settings.siteName,
    siteMeta = settings.siteMeta,
    path = location.pathname.split('/')[1] || 'root';
    let modal = this._getModal();
    let zoomOut = ( this.props.loginModalActive ) ? "app-zoom-out lock-for-modal" : "";
    let renderHeader = !isNil( header ) ? React.cloneElement( header || <div />, { 
      key: 'header-'+ path
    }) : (<div></div>);
    let renderContent = !isNil( content ) ? React.cloneElement( content || <div />, { 
      key: 'page-' + path
    }) : (<div></div>);
    let renderFooter = !isNil( footer ) ? React.cloneElement( footer || <div />, { 
      key: 'footer-' + path
    }) : (<div></div>);
    return (
      <ReactTransitionGroup component="div">
        { modal }
        <div key="app-perspective-container-01" id="app-perspective-container">
          <div key="app-scale-01" id="app-scale" className={ zoomOut }>
            { renderHeader }
            { renderContent }
            { renderFooter }
            { this.props.children }
            <div key="app-overlay-bg-02" id="app-bg-default" />
          </div>
        </div>
        <Helmet
          titleTemplate={title}
          defaultTitle={defaultTitle}
          meta={siteMeta}
        />
      </ReactTransitionGroup>
    );
  }

});

Page.contextTypes = {
  router: React.PropTypes.object.isRequired
};

const mapStateToProps = ( state, ownProps ) => {
  return {
    user: state.user,
    settings: state.settings,
    requestedPath: state.login.requestedPath,
    loginModalActive: state.login.loginModal
  };
};

// redux convenience function, map redux global actions to local props
const mapDispatchToProps = ( dispatch ) => {
  return {
    loginModalToggle: () => dispatch( toggleLoginModal() ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( Page );