import React from 'react';
import ReactTransitionGroup from 'react-addons-transition-group';
import ShopListItem from './shop.list.item';

const ShopList = React.createClass({ 

  propTypes: {
    items: React.PropTypes.array.isRequired,
    onListItemClick: React.PropTypes.func.isRequired
  },

  render: function () {
    let { items, onListItemClick } = this.props;

    return (
      <section className="main-dolls">
        <ReactTransitionGroup component="ul" className="main-dolls-list Grid Grid-full med-Grid-1of3 small-Grid-1of2 large-Grid-fit">
          { items.map((item, index) => {
              return <ShopListItem key={index} idx={index} item={item} clicked={onListItemClick} />
            }) 
          }
        </ReactTransitionGroup>
      </section>
    );
  }

});

export default ShopList;