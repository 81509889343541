let jwtExpression = /^[a-zA-Z0-9+/_=-]+\.[a-zA-Z0-9+/_=-]+\.[a-zA-Z0-9+/_=-]+$/;

// atob native browser base64 decode
// https://www.w3schools.com/jsref/met_win_atob.asp
// btoa native browser base64 encode
const base64Decode = ( value ) => atob( value );

const parseJwt = ( value ) => {
  if ( !value ) {
    return undefined;
  }

  value = value.trim();
  
  if ( value.match( jwtExpression ) === null ) {
    return undefined;
  }

  let [header, body, signature] = value.split('.');

  try {
    return {
      header: JSON.parse( base64Decode( header ) ),
      body: JSON.parse( base64Decode( body ) ),
      signature: signature,
      raw: value
    };
  } catch (err) {
    return undefined;
  }
};

export {
  parseJwt
};