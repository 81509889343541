import { isNil } from 'mightty';
import LocalApi from '../../local/local.api.js';
import HttpCodes from '../utils/http.codes.utils';
import { SubmissionError } from 'redux-form';
import 'whatwg-fetch';
let regeneratorRuntime = require('regenerator-runtime');

/**
 * restApiService
 * class for coordinating rest api requests
 * @author tufandr
 */
class RestApiService {

  constructor() {
    this.res = {};
    this.status = undefined;
    this.config = undefined;
    this.options = {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    };
  };

  // intercept unautorized requests and dispatch loginRequired action
  // loginRequired action sets loginModal: true
  // login modal is displayed to user for credentials
  // @todo move to login.actions may not be needed here
  async checkStatus( response ) {
    console.log( 'response status', response.status );
    this.status = response.status;
    // if ( response.status >= 200 && response.status < 300 ) {
    if ( response.status === HttpCodes.UNAUTHORIZED ) {
      //@todo dispatch LoginRequired Action
    } else {
      return response;
    }
  };

  async getResponse( rawResponse ) {
    let response = undefined;
    response = await this.checkStatus( rawResponse );
    console.log( 'what is the response - getResponse', response );
    return await this.getJson( response );
  };

  async getConfig() {
    this.config = await LocalApi.getSettings();
  };

  async getJson( response ) {
    let json = undefined;
    json = await response.json();
    return json;
  };

  async getApiUrl() {
    if ( isNil( this.config ) ) {
      await this.getConfig();
    } 
    return this.config.API_PROTOCOL + '://' + this.config.API_URL + '/';
  };

  prepareFormData( data ) {
    let formBody = [];
    for (let property in data) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    return formBody.join("&");
  };
  /**
   * post 
   * @returns { response } an object containing the api response 
   */
  async post( data, endpoint ) {
    let response = undefined;
    let API_URL = undefined;
    API_URL = await this.getApiUrl();
    try {
      response = await fetch( API_URL + endpoint, { 
        ...this.options, 
        body: this.prepareFormData( data ), 
        method: 'POST' 
      });
    } catch ( e ) {
      console.log( e );
    }

    let parsedResponse = await this.getResponse( response );
    this.res.status = this.status;
    this.res.response = parsedResponse;

    return this.res;
  };

  /**
   * get 
   * @returns { response } an object containing the api response 
   */
  async get( endpoint ) {
    let response = undefined;
    let API_URL = undefined;
    API_URL = await this.getApiUrl();
    try {
      response = await fetch( API_URL + endpoint, {
        ...this.options
      });
    } catch ( e ) {
      console.log( e );
    }
    let parsedResponse = await this.getResponse( response );
    this.res.status = this.status;
    this.res.response = parsedResponse;

    return this.res;
  };

};

export default new RestApiService();