import React from 'react';
import { isNil } from 'mightty';
import { connect } from 'react-redux';
import { hasPerm } from './acl.service';
import { 
  setRedirectUrl,
  toggleLoginModal 
} from '../../login/login.actions';

const Auth = React.createClass({
  componentWillMount() {

  },

  componentDidMount() {
    let { requestedPath, user } = this.props;
    console.log( 'current user state: ', user );
    console.log( 'auth service props: ', this.props );
    
    if ( !user.authenticated ) {
      // set the current url/path for future redirection (we use a Redux action)
      // then redirect (we use a React Router method)
      this.props.setPath( this.props.requestedPath );
      this.context.router.replace({ 
        pathname: '/'
      });
      this.props.loginModalToggle();
    }
    
  },

  render() {
    const ErrorComponent = () => ( <div>Access Denied: must have appropriate permissions to proceed</div> );
    let { user } = this.props;

    if ( !isNil( user.authenticated ) && user.authenticated && hasPerm( 'manageUsers', user.roleId ) ) {
      if ( this.props.content ) {
        let header = this.props.header || (<div></div>);
        let content = this.props.content || (<div></div>);
        let footer = this.props.footer || (<div></div>);

        return (
          <div>
          { header }
          { content }
          { footer }
          </div>
        );
      } else {
        return this.props.children;
      }
    } else {
      return (<ErrorComponent />);
    }
  }

});

Auth.contextTypes = {
  router: React.PropTypes.object.isRequired
};

// redux convenience function, map redux global state to local props
const mapStateToProps = ( state, ownProps ) => {
  console.log( 'tell me about the state now: ', state );
  return {
    user: state.user,
    settings: state.settings,
    requestedPath: ownProps.location.pathname
  };
};

// redux convenience function, map redux global actions to local props
const mapDispatchToProps = ( dispatch ) => {
  return {
    setPath: ( path ) => dispatch( setRedirectUrl( path ) ),
    loginModalToggle: () => dispatch( toggleLoginModal() )
  };
};

// redux function to attach mapStateToProps and mapDispatchToProps to Dolls module
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( Auth );
