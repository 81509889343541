import React from 'react';
import { connect } from 'react-redux';
//import ExampleLoginModalForm from '../common/components/example.login.modal.form';
import LoginModalFormTest from '../common/components/login.modal.form.test';
import { submit } from 'redux-form'

const ReduxFormExample = React.createClass({

  getInitialState() {
    let inputType = new Map();
    inputType.set(true, 'text');
    inputType.set(false, 'password');
    /* equivalent to obj
    let inputType = {};
    inputType[true] = 'text';
    inputType[false] = 'password';
    */
    return {
      pwReveal: true,
      pwInputType: inputType
    }
  },

  inputs: [],

  componentWillMount() {
    this.myFormData = {
      username: 'josueisnumberone',
      email: 'penawashere@yahoo.com',
      age: 27
    }
  },

  _getInputs( el ) {
    this.inputs.push( el );
    console.log('show me dem inputs', this.inputs);
  },

  _handleSubmit( values ) {
    // Do something with the form values
    console.log(values);
  },

  _updateInputType: function ( input, type = 'text' ) {
    input.setAttribute( 'type', type );
  },

  _submitLoginForm() {
    this.props.submitForm('LoginModalFormTest');
  },

  _togglePasswordInputType() {
    this.setState({
      pwReveal: !this.state.pwReveal
    });
    //this._updateInputType(this.inputs[0], 'password');
  },

  render() {
    return (
      <div>
        <LoginModalFormTest initialValues={this.myFormData} onSubmit={this._handleSubmit} inputRefs={this._getInputs} pwInputType={this.state.pwInputType.get(this.state.pwReveal)}/>
        <button onClick={this._togglePasswordInputType} className="btn btn-doll-customize-shape">Reveal</button>
        <button onClick={this._submitLoginForm} className="btn btn-doll-customize-shape">Continue</button>
      </div>
    );
  }

});

// redux convenience function, map redux global state to local props
const mapStateToProps = ( state ) => {
  console.log( 'tell me about the state now: ', state );
  return {
    dolls: state.dolls,
    order: state.order.dolls.items,
    settings: state.settings,
    signupResponse: state.login.response
  };
};

// redux convenience function, map redux global actions to local props
const mapDispatchToProps = ( dispatch ) => {
  return {
    submitForm: ( formName ) => dispatch( submit( formName ) )
  };
};

// redux function to attach mapStateToProps and mapDispatchToProps to Dolls module
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( ReduxFormExample );