// use thunk with extra params
// thunk.extra( api )
// thunk.extra( { api, other } )
const thunkExtra = ( extra ) => {
  return ({ dispatch, getState }) => next => action => {
    if ( typeof action === 'function' ) {
      return action( dispatch, getState, extra );
    }
    return next( action );
  };
};

const thunk = thunkExtra();
thunk.extra = thunkExtra;

export default thunk;