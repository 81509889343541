import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router';

// stateless view component
const NotFound = () => {
  return (
    <div className="container">
      <Helmet title="Page not found" />
      <h1>Page Not Found.</h1>
      <p>Go to <Link to="/">Home Page</Link></p>
    </div>
  );
};

export default NotFound;