import React from 'react';
import { 
  required,
  maxLength,
  maxLength15,
  number,
  minValue,
  minValue18,
  email,
  tooOld,
  aol 
} from '../utils/simple.validations';
import { Field, reduxForm } from 'redux-form';
import debounce from 'lodash/debounce';
import { addClass, removeClass } from '../utils/dom.utils';
import EyeIcon from './eye.icon';

const renderField = React.createClass({ 
  getInitialState() {
    return {
      value: this.props.input.value
    }
  },

  componentWillMount() {
    this._lastPropValue = this.props.input.value;

    this._defer = debounce( e => {
      console.log( 'im changing value', e.target.value );
      this.props.input.onChange( e.target.value );
    }, 350);

    this._handleChange = ( e ) => {
      e.persist();
      this.setState({ value: e.target.value });
      this._defer( e );
    };
  },

  _getValue() {
    const value = ( this.props.input.value !== this._lastPropValue ) ? this.props.input.value : this.state.value;
    this._lastPropValue = this.props.input.value;
    return value;
  },

  render() {
    let pwFieldExtras = ( this.props.input.name === 'password' ) ? 
    (
      <div>
        <i id="login-modal-reveal-pw" className="reveal-pw active" ref={ this.props.getRevealEl } onClick={this.props.handlePwToggle}> 
          <EyeIcon svgId="login-modal-eye-icon"/>
        </i>
        <span className="hidden forgot-pw">Forgot?</span>
      </div>
    ) :
    ( <span></span> );

    let { input: { name }, label, type, placeholder, autoCorrect, autoCapitalize, formFieldClasses, labelClasses, inputClasses, activeSwitcher , meta: { touched, error, warning, valid, submitFailed } } = this.props;
    let inputWithErrors = ( submitFailed && error ) ? inputClasses + ' error' : inputClasses;
    let formFieldwithBg = ( submitFailed && error ) ? formFieldClasses + ' with-white-bg' : formFieldClasses;
    return (
      <div className={formFieldwithBg}>
        <label className={labelClasses} htmlFor={type}>{label}</label>
        <input placeholder={placeholder} className={inputWithErrors} name={name} value={this._getValue()} onChange={this._handleChange} type={type} autoCapitalize={autoCapitalize} autoCorrect={autoCorrect} />
        { activeSwitcher === 'join' && valid && ( <span className="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span> ) }
        { pwFieldExtras } 
        { submitFailed && touched && ( error && <small className="error" >{error}</small> ) }
      </div>
    );
  }

});

const LoginModalForm = React.createClass({

  getInitialState: function() { 
    let inputType = new Map();
    inputType.set(true, 'text');
    inputType.set(false, 'password');  
    return {
      pwReveal: true,
      pwInputType: inputType
    };
  },

  _handlePwToggle( e ) {
    e.preventDefault();
    if ( e.currentTarget.id === 'login-modal-reveal-pw' && this.state.pwReveal === false ) {
      addClass( this.revealEl, 'active' );
      this.setState({
        pwReveal: true
      });
    } else if ( e.currentTarget.id === 'login-modal-reveal-pw' && this.state.pwReveal === true ) {
      removeClass( this.revealEl, 'active' );
      this.setState({
        pwReveal: false
      });
    }
  },

  _getRevealEl( el ) {
    this.revealEl = el;
  },

  render() {
    console.log( 'tell me about the form props', this.props );
    let { handleSubmit, pristine, reset, submitting, invalid, submitFailed, activeSwitcher } = this.props;
    return (
      <form onSubmit={handleSubmit} className="modal-body" id="app-login-modal-body">
        <Field name="email" type="text"
          placeholder="Email"
          activeSwitcher={activeSwitcher}
          formFieldClasses="form-group has-feedback"
          labelClasses="control-label hidden"
          inputClasses="form-control input-lg" 
          autoCapitalize="none" autoCorrect="off"
          component={renderField} label="Email"
          validate={[ required, email ]}
        />
        <Field name="password" placeholder="Password"
          activeSwitcher={activeSwitcher}
          handlePwToggle={this._handlePwToggle}
          getRevealEl={this._getRevealEl}
          formFieldClasses="form-group has-feedback with-forgot-pw has-icon"
          labelClasses="control-label hidden" 
          inputClasses="form-control input-lg" 
          autoCapitalize="none" autoCorrect="off"
          label="Password" 
          type={this.state.pwInputType.get(this.state.pwReveal)} component={renderField} 
          validate={[ required ]} 
        />
      </form>
    )
  }
});

export default reduxForm({ form: 'LoginModalForm', touchOnChange: true })( LoginModalForm );