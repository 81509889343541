import React from 'react';
import Helmet from 'react-helmet';

const Yolo = React.createClass({
  
  componentDidMount() {
    console.log( 'secret props', this.props );
  },

  render() {
    return (
      <div>
        <Helmet title="You only live once" />
        <h1>This is a super secrect for your eyes only</h1>
      </div>
    );
  }

});

export default Yolo;