import { isNil, isInt } from 'mightty';

const findRoleById = id => {
  console.log( 'role id of user is: ', id );
  if ( !isInt( id ) ) throw new Error('id must be an integer');
  let roles = new Map();
  roles.set(1,'guest');
  roles.set(2,'lead');
  roles.set(3,'customer');
  roles.set(4,'admin');
  return roles.get(id);
};

const getPermByName = name => {

  let perms = new Map();
  perms.set('register', 1 << 0);
  perms.set('editSelf', 1 << 1);
  perms.set('editOwnCart', 1 << 2);
  perms.set('viewOwnOrders', 1 << 3);
  perms.set('manageUsers', 1 << 4);
  perms.set('manageOrders', 1 << 5);

  console.log( 'show perm name requested: ', name );

  return perms.get(name);
};

const getPermByRole = role => {

  let register = 1 << 0,
  editSelf = 1 << 1,
  editOwnCart = 1 << 2,
  viewOwnOrders = 1 << 3,
  manageUsers = 1 << 4,
  manageOrders = 1 << 5,
  guest = register,
  lead = guest | editSelf | editOwnCart,
  customer = lead | viewOwnOrders,
  admin = customer | manageUsers | manageOrders;

  let roles = new Map();
  roles.set( 'guest', guest );
  roles.set( 'lead', lead );
  roles.set( 'customer', customer );
  roles.set( 'admin', admin );

  console.log( 'current role: ', role );

  return roles.get(role);

};

const isAllowed = (role, reqPerm) => {
  console.log( 'isallowed role requested: ', role );
  let bitPerm = getPermByRole( role );
  return ( ( bitPerm & reqPerm ) != 0 ) ? true : false;
};

const hasPerm = ( perm, roleId ) => {
  if (typeof perm !== 'string') throw new Error('perm must be a string');
  let allowed = undefined;
  allowed = isAllowed( findRoleById( roleId ), getPermByName( perm ) );
  return allowed;
};

export {
  hasPerm
};