import React from 'react';
const ShopFooter = React.createClass({
  render: function() {
    return (
      <footer id="home-footer-huge">
      </footer>
    );
  }
});

export default ShopFooter;