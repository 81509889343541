import React from 'react';
import { 
  required,
  maxLength,
  maxLength15,
  number,
  minValue,
  minValue18,
  email,
  tooOld,
  aol 
} from '../utils/simple.validations';
import { Field, reduxForm } from 'redux-form';
import debounce from 'lodash/debounce';


const renderField = ({ input, label, type, meta: { touched, error, warning, submitFailed } }) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={label} type={type}/>
      {submitFailed && touched && ((error && <span>{error}</span>) || (submitFailed && warning && <span>{warning}</span>))}
    </div>
  </div>
);

const renderFormField = ({ inputRef, input, label, type, autoCorrect, autoCapitalize, formFieldClasses, labelClasses, meta: { touched, error, warning, valid, submitFailed } }) => {
  console.log('input prop',input);
  return (
    <div className={formFieldClasses}>
      <label className={labelClasses} htmlFor={type}>{label}</label>
      <input ref={inputRef} {...input} type={type} autoCapitalize={autoCapitalize} autoCorrect={autoCorrect} />
      { valid && ( <span className="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span> ) } 
      { submitFailed && touched && ( error && <small className="error" >Email is required</small> ) }
    </div>
  );
};


const renderDebouncedFormField = React.createClass({ 
  getInitialState() {
    return {
      value: this.props.input.value
    }
  },

  componentWillMount() {
    this._lastPropValue = this.props.input.value;

    this._defer = debounce( e => {
      console.log( 'im changing value', e.target.value );
      this.props.input.onChange( e.target.value );
    }, 350);

    this._handleChange = ( e ) => {
      e.persist();
      this.setState({ value: e.target.value });
      this._defer( e );
    };
  },

  _getValue() {
    const value = ( this.props.input.value !== this._lastPropValue ) ? this.props.input.value : this.state.value;
    this._lastPropValue = this.props.input.value;
    return value;
  },

  render() {
    console.log( 'input props list: ', this.props );
    let { inputRef, input: { name }, label, type, autoCorrect, autoCapitalize, formFieldClasses, labelClasses, meta: { touched, error, warning, valid, submitFailed } } = this.props;
    return (
      <div className={formFieldClasses}>
        <label className={labelClasses} htmlFor={type}>{label}</label>
        <input name={name} ref={inputRef} value={this._getValue()} onChange={this._handleChange} type={type} autoCapitalize={autoCapitalize} autoCorrect={autoCorrect} />
        { valid && ( <span className="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span> ) } 
        { submitFailed && touched && ( error && <small className="error" >{error}</small> ) }
      </div>
    );
  }

});

const LoginModalFormTest = React.createClass({

  _getEmailInput( el ) {
    console.log('storing input', el);
    this.props.inputRefs( el );
  },

  render() {
    console.log( 'tell me about the form props', this.props );
    let { handleSubmit, pristine, reset, submitting, invalid, submitFailed, pwInputType } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field name="username" type="text"
          formFieldClasses="form-group has-feedback"
          labelClasses="control-label hidden" 
          component={renderDebouncedFormField} label="Username"
          validate={[ required, maxLength15 ]}
        />
        <Field name="email" placeholder="Email"
          inputRef={this._getEmailInput}
          formFieldClasses="form-group has-feedback"
          labelClasses="control-label hidden" 
          autoCapitalize="none" autoCorrect="off"
          label="Email" 
          type={pwInputType} component={renderDebouncedFormField} 
          validate={[ required, email ]} 
        />
        <Field name="age" type="number"
          component={renderDebouncedFormField} label="Age"
          formFieldClasses="form-group has-feedback"
          labelClasses="control-label hidden" 
          validate={[ required, number, minValue18 ]}
          warn={tooOld}
        />
        <div>
          <button type="submit" disabled={ ( submitFailed && invalid ) || submitting}>Submit</button>
          <button type="button" disabled={pristine || submitting} onClick={reset}>Clear Values</button>
        </div>
      </form>
    )
  }
});

export default reduxForm({ form: 'LoginModalFormTest', touchOnChange: true })( LoginModalFormTest );