import React from 'react';

const Logo = () => {
  return (
    <div className="row">
      <div id="logo-container">
        <img id="primary-logo" src="dist/assets/images/logo.jpg" />
        <h1 id="logo-text">Hand-Picked by Ruby <small>Hand made, finery</small></h1>
      </div>
    </div>
  );
};

export default Logo;