import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { isNil } from 'mightty';
import { goGetDolls } from '../dolls/dolls.actions';
import { addDoll } from '../order/order.actions';
import { loadSettings } from '../settings/settings.actions';
import ShopList from './shop.list';
import LoadingIndicator from '../common/components/loading';

const Shop = React.createClass({

  componentDidMount() {
    console.log( 'this props route - shop', this.props.route );
    this.props.getDolls();
  },

  _getButtonPos: function ( el ) {
    let xPos = 0,
    yPos = 0;

    xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
    yPos += (el.offsetTop - el.scrollTop + el.clientTop);

   this._button = {
      dimensions: {
        height: el.offsetHeight,
        width: el.offsetWidth
      },
      pos: {
        x: xPos,
        y: yPos
      }
    }; 
  },

  _getButton: function ( el ) {
    this._getButtonPos( el );
  },

  _handleTouchStart: function ( e ) {
    this._coords = {};
    this._coords.touchX = e.touches[0].pageX - this._button.pos.x - (this._button.dimensions.height / 2 );
    this._coords.touchY = e.touches[0].pageY - this._button.pos.y - (this._button.dimensions.width / 2);
    // enables active state animation on buttons for iOS

  },

  _handleClick: function ( item, idx ) {
    console.log( 'was clicked', item );
  },

  _animateIn: function ( done ) {
    let el = ReactDOM.findDOMNode( this );
    TweenLite.set( el, { 
      opacity:0
    });
    TweenLite.to( el, 1, { 
      opacity: 1,
      onComplete: done
     });
  },

  _animateOut: function ( done ) {
    let el = ReactDOM.findDOMNode( this );
    TweenLite.to( el, 1, { 
      opacity: 0,
      onComplete: done
     });
  },

  componentWillAppear: function ( done ) {
    this._animateIn( done );
  },

  componentWillEnter: function ( done ) {
    this._animateIn( done );
  },

  componentWillLeave: function ( done ) {
    this._animateOut( done  )
  },

  _renderList( obj, onItemClick ) {
    let list = undefined;
    const ErrorComponent = () => ( <div>Something went wrong, please try again!</div> );
    if ( obj.loading ) {
      list =  <LoadingIndicator />;
    } else if ( !isNil( obj.error ) ) {
      list = <ErrorComponent />;
    } else if ( !isNil( obj.items ) ) {
      list = ( <ShopList key="home-list-1" items={obj.items} onListItemClick={onItemClick}/> );
    }
    return list;
  },

  render: function () { 
    let { modalActive, dolls } = this.props;
    let dollList = this._renderList( dolls, this._handleClick );
    let contentBlur = ( modalActive ) ? "app-content-blur" : "";
    return (
      <main id="main-content" className={contentBlur}>
        { dollList }
        <Link to="/?login=1">Login</Link>
      </main>
    );
  }

});

Shop.contextTypes = {
  router: React.PropTypes.object.isRequired
};

// redux convenience function, map redux global state to local props
const mapStateToProps = ( state ) => {
  console.log( 'tell me about the state now: ', state );
  return {
    dolls: state.dolls,
    order: state.order.dolls.items,
    settings: state.settings
  };
};

// redux convenience function, map redux global actions to local props
const mapDispatchToProps = ( dispatch ) => {
  return {
    getDolls: () => goGetDolls( dispatch ),
    add: ( doll ) => dispatch( addDoll( doll ) ),
    getSettings: () => dispatch( loadSettings() ),
  };
};

// redux function to attach mapStateToProps and mapDispatchToProps to Dolls module
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( Shop );