import { isNil } from 'mightty';
import LocalApi from '../../local/local.api.js';
import JwtService from './jwt.service';
import API from './rest.api.service';
const regeneratorRuntime = require('regenerator-runtime');

/**
 * UserService
 * class for coordinating user data
 * @author tufandr
 */
class UserService {

  constructor() {
    this.user = undefined;
    this.me = undefined;
    this.config = undefined;
  };

  async getConfig() {
    this.config = await LocalApi.getSettings();
  };

  async getUserDetails() {
    let res = undefined;
    res = await API.get( 'users/me' );
    if ( !isNil( res ) ) {
     this.me = res.response.properties;
    }
    console.log( 'the response is: ', this.me );
  }

  /**
   * getUser 
   * @returns { user } an object with user data 
   */
  async getUser() {
    if ( isNil( this.user ) ) {
      let jwtUser = undefined;
      let jwt = JwtService.getJwt( 'seeme' );
      console.log( 'im a jwt from JwtService - in getUser', jwt );
      if ( !isNil( jwt ) ) {
        jwtUser = {
          id: jwt.sub,
          roleId: jwt.scope,
          authenticated: false
        };
        // guest user should be loaded but not authenticated
        if ( Number( jwtUser.roleId ) !== 1 ) {
          jwtUser.authenticated = true;
        };
      }
      if ( !isNil( jwtUser ) && isNil( this.me ) && Number( jwtUser.roleId )!== 1 ) {
        await this.getUserDetails();
      }
      if ( !isNil( jwtUser ) ) {
        this.user = { ...jwtUser, ...this.me };
      }
      if ( isNil( this.config ) ) {
        await this.getConfig();
      } 
      if ( this.config.devMode && isNil( this.user ) ) {
        /* devMode user example
        this.user = {
          id: 999,
          roleId: 4,
          debugUser: true,
          authenticated: true
        };
        */
      };
    }
    return new Promise((resolve, reject) => {
      resolve( this.user );
    });
  }

};

export default new UserService();