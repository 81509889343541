import { createActions } from 'redux-actions';

const { addDoll, updateProgress } = createActions({
  ADD_DOLL: ( doll ) => ( { doll } ),
  UPDATE_PROGRESS: ( idx, doll ) => ( { idx, doll } )
}); 

export {
  addDoll,
  updateProgress
};