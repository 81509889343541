import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { dollsReducers } from './modules/dolls/dolls.reducers';
import { orderReducers } from './modules/order/order.reducers';
import { settingsReducers } from './modules/settings/settings.reducers';
import { userReducers } from './modules/user/user.reducers';
import { loginReducers } from './modules/login/login.reducers';
import { testReducers } from './modules/test/test.reducers';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
  user: userReducers,
  dolls: dollsReducers,
  order: orderReducers,
  settings: settingsReducers,
  login: loginReducers,
  routing: routerReducer,
  test: testReducers,
  form: formReducer
});

export default rootReducer;