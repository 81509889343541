import React from 'react';
const UserIcon = React.createClass({
  
  render: function() {
    let { svgId } = this.props;
    return (
      <svg viewBox="-10 80 355 355" preserveAspectRatio="xMinYMin meet">
        <g id={ svgId }>
          <path d="M13.87,437.99h292.16c5.94-0.07,10.72-4.91,10.72-10.85c0-55.04-69.41-76.99-99.2-83.9c-0.36-0.1-0.61-0.43-0.61-0.8v-11.84
          c6.89-8.97,12.03-19.15,15.17-30.02c7.84-7.71,11.3-19.78,13.15-26.5c3.04-10.69,6.08-26.18-2.3-37.25l-0.35-0.48
          c4.03-19.55,9.06-65.34-25.82-88.61c4.09-4.17,6.3-9.81,6.14-15.65c-0.04-0.56-0.31-1.08-0.74-1.44c-0.4-0.34-0.91-0.52-1.44-0.51
          c-0.45,0-44.06,4.96-65.09,0.61c-21.63-4.45-45.34,11.94-50.11,28.7c-8.15,1.06-15.56,5.25-20.67,11.68
          c-9.6,11.74-12.45,30.43-8.67,55.52c0.26,3.2,0.64,6.4,1.22,9.6l-0.38,0.48c-8.35,11.07-5.31,26.56-2.27,37.25
          c1.86,6.59,5.31,18.78,13.12,26.5c3.12,10.94,8.27,21.2,15.17,30.24v11.65c0.01,0.38-0.24,0.71-0.61,0.8
          c-29.86,6.88-99.2,28.8-99.2,83.9C3.21,433,7.94,437.86,13.87,437.99z"/>
        </g>
      </svg>  
    );
  }

});

export default UserIcon;