import { isFSA } from 'flux-standard-action';

const isPromise = ( val ) => {
  return val && typeof val.then === 'function';
}

const promiseMiddleware = ({ dispatch }) => {
  return next => action => {
    console.log( 'inside promiseMiddleware, is promise?!', isPromise(action) );
    console.log( 'standard action?!', isFSA(action) );
    if (!isFSA(action)) {
      return isPromise(action) ? action.then(dispatch) : next(action);
    }
    return isPromise( action.payload ) ? action.payload.then(
          // es6 object spread operator
          // https://babeljs.io/docs/plugins/transform-object-rest-spread/
          // equiv 
          // result => dispatch(Object.assign({}, action, {payload:result})),
          result => dispatch({...action, payload: result}),
          error => {
            dispatch({...action, payload: error, error: true});
            return Promise.reject(error);
          }
        ) : next(action);
  };
}

export default promiseMiddleware;