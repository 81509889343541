import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from './root.reducer';
import { routerMiddleware } from 'react-router-redux';
import promiseMiddleware from './vendor/redux-promise';
import reduxThunk from './vendor/redux-thunk';

const configureStore = ( preloadedState, history ) => {
  const middlewares = [
    routerMiddleware( history ),
    reduxThunk,
    promiseMiddleware
  ];
  const enhancers = [];
  // order matters!! createStore params must be ordered: reducer, preloadedState, enhancers 
  return createStore( 
    rootReducer, 
    preloadedState,
    compose(
      applyMiddleware(...middlewares),
      ...enhancers
    )
  );
};

export default configureStore;