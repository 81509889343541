/**
 * DollListItem
 *
 * Doll type, image, and customize button
 */

import React from 'react';
import ListItem from '../common/components/list.item.component';

const DollListItem = ({ xid, item, onSelection }) => {
  // img relative to root
  // http://stackoverflow.com/questions/37644265/correct-path-for-img-on-react-js
  let source = `/${item.imageUrl}`;

  const content = (
    <div className="item">
      <div className="meta">
        <h2>{item.name}</h2>
      </div>
      <div className="thumbnail doll">
        <img src={source} />
        <div className="caption">
          <p className="pricetag">Starts at <span className="price">{item.price}</span></p>
        </div>
      </div>
      <button className="btn btn-primary" role="button">Customize</button>
    </div>
  );  
  // Render the content into a list item
  return (
    <ListItem key={`doll-list-item-${item.name}`} idx={xid} item={content} data={item} classes={item.classes} onSelect={onSelection} />
  );

};

DollListItem.propTypes = {
  item: React.PropTypes.object
};

export default DollListItem;