import React from 'react';
import { isNil } from 'mightty';

const List = ({ component, items, onListItemSelection, size }) => {
  const ComponentToRender = component;
  let content = (<div></div>);
  let view = undefined;
  // If we have items, render them
  if ( items ) {
    content = items.map(( item, index ) => (
      <ComponentToRender key={`item-${index}`} xid={index} item={item} onSelection={onListItemSelection} />
    ));
  } else {
    // Otherwise render a single component
    content = (<ComponentToRender />);
  }

  if ( size === 'sm' ) {
    view = (
    <footer id="doll-list" className="container">
      <div className="row pad-5 gutter-10">
        { content }
      </div>
    </footer>
    );
  } else {
    view = (
      <div className="row gutter-10">
        { content }
      </div>
    )
  }

  return (
    <div>
      { view }
    </div>
  );
}

List.propTypes = {
  component: React.PropTypes.func.isRequired,
  items: React.PropTypes.array,
};

export default List;