import React from 'react';
import { connect } from 'react-redux';
import { toggleLoginModal } from '../../login/login.actions';

class LoginRedirect extends React.Component {
  componentWillMount() {
    this.handleLoginRedirect( this.props );
  }

  componentWillReceiveProps( nextProps ) {
    // prevent loop when canceling modal using direct links
    // ie /login or /?login=1
    if ( nextProps.location.pathname === '/login' && this.props.location.pathname === nextProps.location.pathname 
    || nextProps.location.query.login === "1" && this.props.location.query.login === nextProps.location.query.login ) { 
      this.context.router.replace({ 
        pathname: '/',
        query: {}
      });
    } else {
      this.handleLoginRedirect( nextProps ); 
    }
  }

  handleLoginRedirect( props ) {
    const { location } = props;
    const state = location.state || {};
    const onLoginRoute = location.query.login || location.pathname === '/login' || state.login;
    const needsStateChange = onLoginRoute && !props.loginModalActive && !props.authenticated;

    // prevent login without logout & reset query 
    if ( onLoginRoute && props.authenticated ) {
      // if location is /login, reset location otherwise just prevent login modal
      let newLocation = ( location.pathname === '/login' ) ? '/' : location.pathname;
      this.context.router.replace({
        pathname: newLocation, 
        query: {}
      });
    }

    if ( needsStateChange ) {
      props.loginModalToggle();
    }
  }

  render() {
    return React.Children.only( this.props.children );
  }
};

LoginRedirect.contextTypes = {
  router: React.PropTypes.object.isRequired
};

const mapStateToProps = ( state, ownProps ) => {
  return {
    authenticated: state.user.authenticated,
    loginModalActive: state.login.loginModal
  };
};

// redux convenience function, map redux global actions to local props
const mapDispatchToProps = ( dispatch ) => {
  return {
    loginModalToggle: () => dispatch( toggleLoginModal() ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( LoginRedirect );