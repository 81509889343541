import { handleActions } from 'redux-actions';
import uuid from 'uuid';

const initialState = {
  id: uuid.v4(), 
  dolls: { 
    loading: false,
    error: undefined,
    items: [] 
  }
};

const wizardDefault = {
  step: 1,
  totalSteps: 3,
  title: 'Choose Colors',
  progress: 0
};

const addWizard = ( doll ) => ({ ...doll, wizard: { ...wizardDefault } });

const reducers = {
  ADD_DOLL: ( state, action ) => ({
    id: state.id,
    dolls: { ...state.dolls, items: [...state.dolls.items, addWizard( action.payload.doll )] }
  }),
  UPDATE_PROGRESS: ( state, action ) => ({
    id: state.id,
    dolls: { ...state.dolls, items: [
    ...state.dolls.items.slice( 0, action.payload.idx ),
    { ...state.dolls.items[action.payload.idx], ...action.payload.doll },
    ...state.dolls.items.slice( action.payload.idx + 1 )
    ]}
  })
};

const orderReducers = handleActions( reducers, initialState );

export {
  orderReducers
};