import { handleActions } from 'redux-actions';

// redux inital state aka defaults
const initialState = {
  authenticated: false
};

// redux reducers
const reducers = {
  LOAD_USER: ( state, action ) => ({ ...state, ...action.payload })
};

/**
 * settingsReducers
 * Apply reducers to redux state, 
 * works in tandem with createActions in settings.actions.js
 * 
 * @param {object} reducers - collection of methods that act on redux state
 * @param {object} initialState - defaults for redux state
 */
const userReducers = handleActions( reducers, initialState );

export {
  userReducers
};