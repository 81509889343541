import React from 'react';
const LogoType = React.createClass({
  
  render: function() {
    let { svgId } = this.props;
    return (
      <svg viewBox="-159.5 543.5 320 50" preserveAspectRatio="xMinYMin meet">
        <filter id="constantOpacity">
          <feComponentTransfer>
            <feFuncA type="table" tableValues="0 .7 .7" />
          </feComponentTransfer>
        </filter>
        <g id={ svgId } strokeWidth="2" stroke="rgba(39,40,34,.7)">
        <path fill="none" d="M-148.31,559.58c-1.29,0.01-2.09,0.79-2.17,2.08c-0.06,1.03,0.68,1.37,1.71,1.48c1.01-0.06,1.86-0.54,2-1.74
        C-146.63,560.31-147.22,559.58-148.31,559.58z"/>
        <path d="M-154.09,561.27c0.51-3.4,3.07-6.41,5.66-6.67c3.16-0.32,7.24,1.68,8.2,4.06c0.58,1.44,0.79,2.98-0.72,3.89
        c-1.39,0.83-2.83,0.53-3.82-0.9c-0.53-0.76-0.94-1.62-1.54-2.32c-1.13-1.31-2.55-1.34-3.91-0.41c-1.57,1.07-2.02,2.71-1.28,4.38
        c0.77,1.74,2.46,1.6,3.72,0.83c1.26-0.76,2.13-0.71,3.37-0.04c1.55,0.83,3.06,0.12,4.32-0.86c1.11-0.86,1.69-2.04,1.37-3.55
        c-0.67-3.07-4.33-6.41-7.44-6.75c-3.58-0.39-7.62,2.16-9.14,5.76c-1.44,3.41-0.61,8.08,1.78,10.1c3.33,2.81,11.63,2.09,14.65-1.78
        c-2.03,0.62-4.03,1.39-6.09,1.85C-150.69,570.15-154.89,566.58-154.09,561.27z M-146.76,561.39c-0.15,1.2-0.99,1.67-2,1.74
        c-1.03-0.1-1.77-0.45-1.71-1.48c0.08-1.29,0.88-2.07,2.17-2.08C-147.22,559.58-146.63,560.31-146.76,561.39z"/>
        <path d="M-31.35,555.01c0.5,0.05,0.67-0.33,0.73-0.76c-0.01-0.45-0.2-0.83-0.7-0.72c-0.24,0.05-0.52,0.35-0.59,0.59
        C-32.02,554.55-31.84,554.96-31.35,555.01z"/>
        <path d="M-41.35,570.11c2.74-2.83,1.75-8.22-1.31-10.3c-2.34-1.6-4.88-0.38-7.45,3.56c-0.11,0.18-0.18,0.38-0.31,0.54
        c-1.54,1.93-2.67,4.23-4.9,5.54c-1.53,0.9-3.13,2.1-4.86,0.73c-1.56-1.22-1.01-2.96-0.6-4.62c1.36-5.45,3.25-10.72,5.12-16
        c0.36-1.02,1.15-2.02,0.48-3.27c-1.42,0.47-1.71,1.73-2.09,2.88c-0.98,3.05-1.9,6.14-2.86,9.22c-0.37,1.2-0.66,2.52-2.48,1.93
        c-4.61-1.49-7.76,0.65-10.36,4.18c-1.42,1.94-3.06,3.67-4.94,5.17c-0.74,0.59-1.58,1.19-2.54,0.54c-0.85-0.57-1.03-1.51-0.95-2.49
        c0.1-1.13,0.31-2.28,0.27-3.41c-0.17-3.75-2.78-5.18-5.99-3.24c-1.29,0.78-2.39,1.84-3.65,2.83c-0.73-1.99-0.31-4.36-2.92-4.92
        c-2.49-0.53-3.81,1.15-5.2,2.72c-1.11,1.25-2.09,2.6-2.67,4.19c-0.67,1.81-1.88,3.22-3.34,4.42c-0.76,0.64-1.69,0.94-2.56,0.25
        c-0.76-0.6-0.62-1.46-0.31-2.29c0.52-1.47,1.18-2.9,1.49-4.42c0.54-2.78-1.18-4.33-3.98-3.86c-3.05,0.52-5.41,2.07-7.01,4.71
        c-1.32,2.18-3.14,3.87-5.19,5.32c-1.64,1.16-2.77,0.79-2.86-1.37c-0.07-1.76-0.04-3.53-0.03-5.31c0.01-1.95-0.65-3.29-2.9-3.08
        c-1.59,0.15-1.73-0.55-1.27-1.85c0.62-1.75,1.27-3.53,1.66-5.34c0.38-1.78,0.42-3.82-1.76-4.41c-2.07-0.56-3.2,1.09-4.03,2.72
        c-1.2,2.34-1.59,4.93-2,7.49c-0.74,4.52-1.37,9.07-2.05,13.6c1.93-2.83,2.84-6.05,5.06-8.53c1.34-1.49,2.9-3.19,5.03-2.18
        c1.95,0.93,0.78,2.93,0.66,4.5c-0.17,2.25-0.94,5,1.2,6.36c2.09,1.32,3.67-1.13,5.37-2.17c0.09-0.05,0.17-0.13,0.23-0.21
        c0.9-0.98,1.62-1.02,2.44,0.2c1.04,1.58,2.73,2.72,4.5,2.12c2.35-0.8,4.47-0.9,6.97-0.69c3.28,0.27,4.9-2.76,5.94-5.55
        c0.76-2.06,1.98-3.64,3.68-4.88c2.48-1.76,4.23-0.95,4.26,2.12c0.02,1.95-0.51,3.9-0.83,5.84c-0.12,0.74-0.5,1.49,0.27,2.7
        c0.8-1.71,1.37-3,2-4.26c0.92-1.87,1.86-3.72,3.54-5.06c0.91-0.72,1.87-1.44,3.11-0.88c1.28,0.59,1.78,1.76,1.76,3.06
        c-0.02,1.45-0.38,2.89-0.35,4.33c0.06,2.44,1.56,3.42,3.75,2.4c1.3-0.6,2.43-1.58,3.63-2.39c0.78-0.52,1.47-1.28,2.13,0.23
        c1.29,2.95,3.94,2.95,6.38,2.25c2.43-0.71,4.43-1.79,7.06,0.02c1.54,1.05,3.8-0.06,5.51-1.22c0.8-0.53,1.28-1.63,2.79-1.54
        c-1.01,4.69-2,9.29-2.99,13.9c0.29,0.06,0.56,0.13,0.85,0.2c1.36-2.77,1.78-5.84,2.72-8.74c0.33-1.02,0.33-2.84,2.27-2.22
        c4.66,1.48,8.72,0.26,12.42-2.74c1.53,4.03,2.68,4.34,6.05,1.73c1.34-1.04,2.55-2.25,3.88-3.42c3.88,7.36,8.94,3.19,13.86,1.49
        c-0.23,1.27-0.39,2.16-0.6,3.34c1.53-0.79,1.22-1.88,1.49-2.72c1.03-3.18,2.25-6.27,4.73-8.66c1.16-1.12,2.36-1.24,3.51,0.05
        c1.17,1.33,1.13,2.68-0.21,3.81c-0.6,0.51-1.48,0.7-2.2,1.08c-0.61,0.32-1.56,0.48-1.41,1.42c0.29,1.86,0.83,3.7,2.4,4.9
        c1.59,1.22,3.32,0.75,4.56-0.46c2.23-2.17,3.82-3.31,6.58-0.21c1.95,2.21,7.22,0.99,10.04-1.33c0.69-0.57,1.3-2.07,2.39-0.5
        c2.29,3.28,5.23,2.15,8.12,1.23c0.85-0.27,1.69-1.43,2.52-0.73c3.56,3.02,6.51,1.39,9.42-0.99c0.57-0.46,0.94-0.93,1.72-0.04
        c5.31,5.93,7.97,5.83,13.6-0.33c0.95-1.03,5.44-1.41,5.99-0.5c2.02,3.41,4.79,4.18,8.48,2.59c0.99-0.43,1.95-2.46,3.02-1.02
        c1.28,1.71-0.55,2.65-1.82,3.56c-1.69,1.22-3.21,2.66-4.38,4.39c-1.17,1.71-2.32,3.45-2.29,5.67c0.03,2.67,2.5,5.94,4.94,6.04
        c2.92,0.12,3.75-2.23,4.35-4.46c0.78-2.91,1.25-5.89,1.14-8.93c-0.04-1.15-0.04-2.25,1.25-2.92c3.77-1.94,5.82-5.17,6.77-9.23
        c0.33-1.41,0.72-2.95,2.86-2.39c1.85,0.48,3.31,1.07,2.6,3.54c-0.3,1.05-0.21,2.29-0.07,3.41c0.39,3.29,2.57,4.25,5.26,2.36
        c0.67-0.47,1.28-1.06,1.84-1.68c0.98-1.07,1.33-0.65,1.9,0.47c1.73,3.45,3.77,3.79,6.92,1.44c1.19-0.9,1.86-2.43,3.41-3
        c1.41,4.05,2.77,4.43,6.04,1.79c1.32-1.06,2.53-2.27,4.16-3.74c0.2,4.49,2.59,6.59,6.2,7.14c2.88,0.43,5.45-0.83,6.9-3.31
        c1.03-1.74,2.42-1.67,3.99-2.17c2.43-0.76,3,0.35,3.71,2.17c0.43,1.1,1.61,1.55,2.86,1.58c2.12,0.06,4.13-0.28,5.96-1.44
        c0.57-0.36,1.21-1.56,1.98-0.36c0.62,0.96,0.54,2.03-0.57,2.71c-1.97,1.21-3.67,2.74-5.12,4.51c-1.46,1.78-2.74,3.7-2.92,6.08
        c-0.21,2.72,1.93,5.82,4.59,6.29c3.01,0.53,3.81-1.76,4.59-3.99c0.85-2.39,1.22-4.86,1.06-7.35c-0.18-2.75,0.59-4.53,3.15-6.02
        c2.72-1.56,4.55-4.12,4.07-7.62c-1.79,2.04-2.52,4.76-4.66,6.5c-0.56,0.45-1.11,1.05-1.92,0.76c-0.89-0.33-0.67-1.23-0.69-1.89
        c-0.06-2.37-0.03-4.75-0.03-7.2c-1.31,0.67-1.27,1.98-1.75,2.96c-0.79,1.62-1.67,3.12-3.42,3.88c-1.72,0.74-3.52,1.68-5.33,0.52
        c-1.93-1.26-1.27-3.32-1.13-5.15c0.1-1.25,1.01-2.49,0.08-3.82c-1.08,0.41-1.04,1.31-1.22,2.07c-0.74,3.24-2.87,4.42-5.79,2.86
        c-1.87-0.99-1.49,0.29-1.74,1.23c-0.94,3.46-4.83,5.41-8.11,4.12c-1.1-0.43-2.11-1.04-2.41-2.25c-0.37-1.48,1.11-1.57,1.87-2.09
        c5.12-3.49,7.25-11.01,4.61-16.61c-0.63-1.33-1.28-2.75-3.03-2.75c-1.55-0.01-2.33,1.2-3.04,2.37c-2.13,3.49-2.84,7.45-2.74,11.38
        c0.11,4.31-2.77,6.2-5.52,8.35c-0.76,0.59-1.74,0.85-2.61,0.19c-0.83-0.64-0.95-1.65-0.7-2.57c0.47-1.8,1.1-3.56,1.65-5.34
        c0.26-0.81,1.19-1.91,0-2.35c-0.98-0.37-1.04,1-1.31,1.72c-1.19,3.15-2.68,6.11-5.49,8.15c-1.2,0.88-2.53,1.6-4.05,0.88
        c-1.58-0.74-1.58-2.25-1.68-3.74c-0.2-2.51,1.22-4.76,1.23-7.46c-1.49,1.11-1.91,2.57-2.05,3.96c-0.26,2.63-1.86,4.31-3.6,5.97
        c-0.89,0.85-1.88,2.04-3.22,1.34c-1.44-0.74-1.19-2.34-0.95-3.65c0.36-1.97,0.95-3.89,1.63-6.63c-2.04,1.58-3.69,1.4-4.83,0.25
        c-1.95-1.97-2.65-0.83-3.5,0.95c-1.24,2.62-2.49,5.26-3.96,7.75c-0.76,1.28-1.87,3.16-3.5,2.49c-1.25-0.52-0.98-2.58-0.83-4.03
        c0.18-1.57,0.66-3.13,0.12-5.06c-1.56,1.44-1.64,3.24-2.55,4.58c-1.62,2.37-5.62,4.2-7.54,3.15c-2.82-1.56-1.71-4.11-1.31-6.4
        c0.18-1.04,0.82-2.09-0.44-2.96c-3.37,6.6-3.51,6.68-8.01,4.71c-1.46,5.06-5.35,7.43-9.05,5.53c-2.43-1.25-2.49-2.51-0.21-4.05
        c5.03-3.35,7.32-10.49,5.07-16c-0.62-1.53-1.29-3.27-3.24-3.43c-2-0.16-2.85,1.51-3.6,2.97c-1.96,3.78-2.7,7.88-2.37,12.07
        c0.24,3.18-1.26,5.09-3.69,6.62c-1.43,0.9-2.96,1.88-4.61,0.73c-1.66-1.17-1.19-2.92-0.77-4.56c1.42-5.64,3.37-11.12,5.32-16.59
        c0.33-0.91,0.92-1.77,0.29-2.82c-1.39,0.61-1.64,1.79-1.99,2.84c-1.03,3.16-2.02,6.33-2.95,9.51c-0.41,1.41-0.98,2.06-2.65,1.66
        c-4.64-1.1-8.33,0.64-10.43,5.05c-1.35,2.83-3.47,4.62-6.19,5.82c-1.68,0.74-3.44,0.87-5.06-0.23c-1.14-0.78-1.68-1.48,0.04-2.4
        c1.74-0.93,3.27-2.25,4.14-4.12c0.68-1.48,1.15-3.02-0.55-4.15c-1.64-1.09-3.16-0.68-4.47,0.68c-1.17,1.22-1.86,2.74-2.44,4.3
        c-0.9,2.41-2.4,4.38-4.52,5.81c-2.37,1.6-4.47,0.57-5.01-2.22c-0.33-1.75,0.98-2.05,1.97-2.73c1.59-1.09,2.39-2.42,1.87-4.51
        c-0.49-1.96-1.41-2.8-3.5-2.57c-1.94,0.21-1.17-1.35-0.98-2.16c0.54-2.32,1.22-4.63,1.14-7.05c-0.03-0.91,0.2-2.1-0.9-2.42
        c-1.03-0.3-1.66,0.69-2.21,1.44c-2.56,3.52-3.31,7.52-3.19,11.79c0.15,5.48-3.36,9.34-8.85,9.84c-2.6,0.23-4.15-0.88-4.56-3.54
        c-0.32-2.05,0.32-3.69,1.85-5c1.87-1.61,3.69-0.91,5.47,0.46c-2.1-3.81-5.47-3.59-7.81,0.4c-1.44,2.46-3.15,4.64-5.22,6.55
        c-0.89,0.83-1.87,2.06-3.21,1.37c-1.5-0.78-0.93-2.38-0.71-3.64c0.22-1.32,0.79-2.58,1.08-3.89c0.25-1.11,1.81-2.79,0.53-3.18
        c-1.48-0.44-1.42,1.81-1.76,2.97c-1.19,4-3.62,7.16-7.45,8.22c-0.39,0.11-1.51,0.16-1.61-0.09c-0.09-0.27,0.88-0.4,1.18-0.49
        C-42.08,570.55-41.55,570.32-41.35,570.11z M-134.77,563.33c0.23-4.25,0.56-7.62,1.87-10.81c0.6-1.48,1.56-3.08,3.42-2.32
        c1.93,0.79,0.92,2.58,0.4,3.78C-130.38,557.09-131.83,560.15-134.77,563.33z M-113.46,571.23c-2.41-0.27-3.79-1.35-3.16-3.71
        c0.76-2.86,2.63-4.9,5.39-5.99c0.95-0.37,2.09-0.48,2.94,0.29c0.97,0.86,0.9,1.96,0.57,3.14
        C-108.78,568.92-111.03,571.32-113.46,571.23z M-69.08,570.89c-2.19-0.16-3.63-2.22-3.14-4.45c0.74-3.28,5.13-5.77,8.32-4.77
        c1.29,0.4,1.85,1.23,1.8,2.4C-62,567.27-66.01,571.11-69.08,570.89z M-49.34,564.47c1.11-2.09,2.86-3.86,5.4-3.43
        c2.45,0.41,2.89,2.67,2.99,4.51c-0.02,3.84-1.55,4.71-4.72,3.03c-0.63-0.33-1.37-0.75-2.04-0.7
        C-51.24,568.13-50.22,566.1-49.34,564.47z M-46.03,571.44c-0.4,0.14-0.88,0.01-1.3,0.01c-0.48,0-2.12-1.13-1.87-1.87
        c0.22-0.66,0.86-0.65,1.47-0.81c1.65-0.43,2.9,0.81,2.23,2.2c-0.08,0.16-0.18,0.27-0.28,0.34 M-9.78,561.27
        c-0.73-3.67-0.15-7.03,1.62-10.17c0.31-0.53,0.67-1.13,1.42-0.95c0.8,0.2,0.69,0.95,0.69,1.54
        C-6.07,555.34-7.73,558.38-9.78,561.27z M5.7,565.35c0.35-2.02,1.01-3.94,3.43-4.23c1.03-0.13,1.76,0.48,1.66,1.71
        c-0.12,1.41-2.89,4.4-4.37,4.34C5.43,567.09,5.56,566.11,5.7,565.35z M22.53,570.9c-1.85-0.07-2.91-1.53-3-3.6
        c-0.12-3.02,3.42-5.88,6.96-5.8c1.83,0.04,3.34,0.52,3.37,2.76C29.89,567.13,25.99,570.91,22.53,570.9z M41.15,563.12
        c0.65-3.91,1-7.84,2.5-11.5c0.41-1.01,0.86-2.35,2.23-2.24c1.15,0.09,1.44,1.28,1.85,2.25c2.06,4.87,0.43,9.07-2.35,13.01
        c-0.76,1.08-1.95,2.27-3.34,1.73C40.7,565.85,41.38,564.21,41.15,563.12z M71.95,577.73c0.05,0.62,0.01,1.24,0.01,1.86
        c-0.03,1.97,0.21,4-0.7,5.82c-0.81,1.62-1.62,3.75-4.03,2.91c-2.2-0.76-3.17-2.73-2.81-4.99c0.48-2.99,2.21-5.33,4.65-7.1
        c0.73-0.53,1.54-1.39,2.47-0.83C72.32,575.89,71.9,576.94,71.95,577.73z M117.49,564.01c0.33-4.35,0.98-8.65,2.63-12.73
        c0.38-0.95,0.88-2.05,2.13-1.93c1.01,0.09,1.51,1.04,1.83,1.93c0.56,1.55,1.15,3.12,0.97,4.85c-0.45,3.55-1.38,6.92-4.21,9.41
        c-0.69,0.6-1.54,1.25-2.53,0.88C117.19,566.02,117.42,564.89,117.49,564.01z M147.22,586.22c-0.65,1.17-1.43,2.43-3,2.31
        c-1.45-0.12-2.38-1.17-3.01-2.41c-1.64-3.21,2.4-10.47,5.99-10.86c1.04-0.12,1.25,0.46,1.27,1.26c0.03,1.03,0.01,2.07,0.01,3.09
        C148.51,581.89,148.35,584.17,147.22,586.22z"/>
        </g>
      </svg>
    );
  }

});

export default LogoType;