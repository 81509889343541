import React from 'react';

const Loading = () => {
  return (
    <div className="loading-indicator">
      <h5 className="vertical-align">
        <i className="spinner">
          <span className="bar1"></span>
          <span className="bar2"></span>
          <span className="bar3"></span>
          <span className="bar4"></span>
          <span className="bar5"></span>
          <span className="bar6"></span>
          <span className="bar7"></span>
          <span className="bar8"></span>
        </i>
        Loading...
      </h5>
    </div>
  );
};

export default Loading;