import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { isNil } from 'mightty';
import { goGetDolls } from '../dolls/dolls.actions';
import { addDoll } from '../order/order.actions';
import { trySignup } from '../login/login.actions';
import { loadSettings } from '../settings/settings.actions';
import List from '../common/components/list.component';
import ListItem from '../common/components/list.item.component';
import DollListItem from '../dolls/doll.list.item';
import Logo from '../common/components/logo';
import LoadingIndicator from '../common/components/loading';
import H1 from '../common/components/h1';

class Shop extends React.Component {

  // react only needs for class based components
  constructor ( props ) {
    super ( props );
    this.chooseDoll = this.chooseDoll.bind(this);
    this.ajax = this.ajax.bind(this);
  };

  componentDidMount () {
    this.props.getDolls();
  };

  /**
   * convenience function for route changes
   * @param {string} route - The route we want to go to
   */
  openRoute ( route ) {
    this.props.changeRoute( route );
  };

  ajax () {
    this.props.attemptSignup();
  };

  chooseDoll ( doll ) {
    console.log( 'i choose you:', doll );
    this.props.add( doll );
    let id = this.props.order.length;
    console.log( 'my current id: ', id);
    // can pass a string or LocationDescriptor object
    // https://github.com/mjackson/history/blob/v2.x/docs/Glossary.md#locationdescriptor
    // could be used for pathless customizer states?
    this.openRoute({
      pathname: `/customizer/${id}`, 
      state:{ id } 
    });
  };

  renderList( obj, type, selection ) {
    let list = undefined;
    const ErrorComponent = () => ( <ListItem item={'Something went wrong, please try again!'} /> );
    if ( obj.loading ) {
      list = ( <List component={LoadingIndicator} /> );
    } else if ( !isNil( obj.error ) ) {
      list = ( <List component={ErrorComponent} /> );
    } else if ( !isNil( obj.items ) ) {
      list = ( <List items={obj.items} component={type} onListItemSelection={selection} /> );
    }
    return list;
  };

  render() {
    let dollList = null,
    outfitList = null,
    { loading, dolls, error, settings, signupResponse } = this.props;
    dollList = this.renderList( dolls, DollListItem, this.chooseDoll );

    return (
      <div className="row">
        <Helmet title="Shop" />
        <div className="col-md-16">
          <Logo />
          <H1 text='Dolls' />
          {dollList}
          <H1 text='Outfits' />
          {outfitList}
          <H1 text={settings.message} />
          <div className="btn btn-primary btn-lg" role="button" onClick={this.ajax}>Register</div>
        </div>
      </div>
    );
  };
};

// react prop validation
Shop.propTypes = {
  dolls: React.PropTypes.object.isRequired,
  getDolls: React.PropTypes.func.isRequired,
  add: React.PropTypes.func.isRequired,
  changeRoute: React.PropTypes.func.isRequired
};

// redux convenience function, map redux global state to local props
const mapStateToProps = ( state ) => {
  console.log( 'tell me about the state now: ', state );
  return {
    dolls: state.dolls,
    order: state.order.dolls.items,
    settings: state.settings,
    signupResponse: state.login.response
  };
};

// redux convenience function, map redux global actions to local props
const mapDispatchToProps = ( dispatch ) => {
  return {
    getDolls: () => goGetDolls( dispatch ),
    add: ( doll ) => dispatch( addDoll( doll ) ),
    changeRoute: ( url ) => dispatch( push( url ) ),
    getSettings: () => dispatch( loadSettings() ),
    attemptSignup: () => dispatch( trySignup() )
  };
};

// redux function to attach mapStateToProps and mapDispatchToProps to Dolls module
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( Shop );
