// test for native promise support, implement bluebird as default Promise handler
if ( typeof Promise === "undefined" || Promise.toString().indexOf("[native code]") === -1 ){
  window.Promise = require("bluebird");
};
// import core-js es6 symbol and array polyfills for safari 9
import 'core-js/es6/symbol';
import 'core-js/es6/array';
import 'core-js/es6/map';
import React from 'react';
import { render } from 'react-dom';
import { Router, browserHistory } from 'react-router';
import routes from './routes';
import configureStore from './store';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';
import { loadSettings } from './modules/settings/settings.actions';
import { loadUser } from './modules/user/user.actions';
// regeneratorRuntime required for babel transpiling of async/await
import regeneratorRuntime from 'regenerator-runtime';

// preloaded state
// ie state from server, cookie, or localstorage
const preloadedState = {};
const store = configureStore( preloadedState, browserHistory );
const history = syncHistoryWithStore( browserHistory, store );
// wait for loadSettings and loadUser then render
const init = async () => {
  // load local app settings
  await store.dispatch( loadSettings() );
  // load local user -- used for testing
  await store.dispatch ( loadUser() );

  render(
    <Provider store={ store }>
      <Router history={ history } routes={ routes } />
    </Provider>,
    document.getElementById('app')
  );

};
init();