import config from './local.config';
import dolls from './local.dolls';
import user from './local.user';
import { isNil } from 'mightty';

/**
 * LocalApi
 * Mock api for local config
 * @author tufandr
 */
class LocalApi {

  /**
   * getSettings 
   * @returns { config } an object with settings defined in 
   * local.config.js
   */
  static getSettings() {
    return new Promise((resolve, reject) => {
      resolve( config );
    });
  }

  static getDolls() {
    return new Promise((resolve, reject) => {
      if ( !isNil( this.dolls ) ) resolve ( this.dolls )
      setTimeout(() => {
        this.dolls = dolls;
        resolve( dolls );
      }, this.delay);
    });
  }

  static authenticate() {
    return new Promise((resolve, reject) => {
      resolve( user );
    });
  }

};
// define delay length for testing
LocalApi.delay = config.devDelay;
LocalApi.dolls = undefined;

export default LocalApi;