import { createActions } from 'redux-actions';
import LocalApi from '../local/local.api.js';

// required for async methods
let regeneratorRuntime = require('regenerator-runtime');

/**
 * { destructued } actionTypes correspond to actionCreators
 * createActions could be named createActionCreators
 * works in tandem with handleActions in settings.reducers.js
 * 
 * @param {object} actions - collection of combined action type definitions
 * and methods
 */
const { loadSettings } = createActions({
  LOAD_SETTINGS: async () => await LocalApi.getSettings()
});

export {
  loadSettings
};