import { createAction, createActions } from 'redux-actions';
import { isNil } from 'mightty';
import { loadUser } from '../user/user.actions';
import { SubmissionError } from 'redux-form';
import API from '../common/services/rest.api.service';
import HttpCodes from '../common/utils/http.codes.utils';
// regeneratorRuntime required for babel transpiling of async/await
import regeneratorRuntime from 'regenerator-runtime';


// login actions
const fetchStart = createAction( 'FETCH_START' );
const fetchEnd = createAction( 'FETCH_END' );
const fetchSuccess = createAction( 'FETCH_SUCCESS' );
const fetchError = createAction( 'FETCH_ERROR' );
const setRedirectUrl = createAction( 'SET_REDIRECT_URL' );
const loginModalToggle = createAction( 'TOGGLE_LOGIN_MODAL' );

const toggleLoginModal = () => {
  return ( dispatch, getState ) => {
    const { user: { authenticated } } = getState();
    console.log( 'are we authenticated?', authenticated );
    // prevent login without logout for authenticated user
    if ( !authenticated ) {
      dispatch( loginModalToggle() );
    }
  };
};

const validateUser = async ( email, password, endpoint ) => {

  let res = undefined;

  let data = {
    email,
    password
  };

  res = await API.post( data, endpoint );
  //console.log( 'catching error in login-actions-validateUser', e instanceof SubmissionError );

  console.log( 'the response is: ', res );
  return res;

};

const login = async ( email, password ) => {
  let res = undefined;
  res = await validateUser( email, password, 'oauth2/token' );
  return res;
};

const register = async ( email, password ) => {
  let res = undefined;
  res = await validateUser( email, password, 'users' );
  return res;
};

const logout = async () => {
  let res = undefined;
  res = await API.get( 'logout' );
  console.log( 'the response is: ', res.response );
  return res.response;
};

//@todo refactor too much happening here
const userDispatch = ( type, email, password ) => {
  return async ( dispatch ) => {
    dispatch( fetchStart({ fetching: true }) );
    let res = undefined;
    if ( type === 'login' ) {
      res = await login( email, password ); 
    } else if ( type === 'register' ) {
      res = await register( email, password );
    }

    return new Promise((resolve, reject) => {
      if ( res.status === HttpCodes.BAD_REQUEST ) {
        dispatch( fetchEnd({ fetching: false }) );
        dispatch( fetchError( res.response.messages ) );
        throw new SubmissionError({ email: res.response.messages[0], _error: res.response.messages[0] });
        reject();
      } else {
        dispatch( fetchSuccess( res.response ) );
        dispatch( loadUser() );
        dispatch( toggleLoginModal() );
        dispatch( fetchEnd({ fetching: false }) );
        resolve( res.response );
      }
    });
  }
};

export {
  userDispatch,
  fetchStart,
  fetchSuccess,
  fetchEnd,
  fetchError,
  toggleLoginModal,
  setRedirectUrl
};
