import CookieService from './cookie.service';
import { parseJwt } from '../utils/jwt.utils';
import { isNil } from 'mightty';

/**
 * JwtService
 * class for coordinating local json web tokens
 * @author tufandr
 */
class JwtService {

  constructor() {
    this.jwt = undefined;
  };

  /**
   * getJwt
   * @returns { jwt } an object with jwt claims 
   */
  getJwt( key ) {
    let cookie = undefined;
    let jwt = undefined;
    if ( !isNil( this.jwt ) ) {
      return this.jwt;
    } else {
      cookie = CookieService( key );
      console.log( 'this is my cookie from cookie service', cookie );
      if ( !isNil( cookie ) ) {
        jwt = parseJwt( cookie );
      }
      if ( !isNil( jwt ) ) {
        this.jwt = jwt.body;
      }
      return this.jwt;
    }
  }

};

export default new JwtService();