import { handleActions } from 'redux-actions';

// redux inital state aka defaults
const initialState = {
  response: undefined,
  fetching: false,
  errors: ['nonya bizness'],
  loginModal: false,
  requestedPath: undefined
};

// redux reducers
const reducers = {
  FETCH_START: ( state, action ) => ({ ...state, ...action.payload }),
  FETCH_END: ( state, action ) => ({ ...state, ...action.payload }),
  FETCH_SUCCESS: ( state, action ) => ({ ...state, response: action.payload }),
  FETCH_ERROR: ( state, action ) => ({ ...state, errors: [ ...state.errors, ...action.payload ] }),
  SET_REDIRECT_URL: ( state, action ) => ({ ...state, requestedPath: action.payload }),
  TOGGLE_LOGIN_MODAL: ( state, action ) => ({ ...state, loginModal: !state.loginModal })
};

/**
 * settingsReducers
 * Apply reducers to redux state, 
 * works in tandem with createActions in settings.actions.js
 * 
 * @param {object} reducers - collection of methods that act on redux state
 * @param {object} initialState - defaults for redux state
 */
const loginReducers = handleActions( reducers, initialState );

export {
  loginReducers
};