import React from 'react';
import ReactDOM from 'react-dom';
import UserIcon from '../common/components/user.icon';
import LogoType from '../common/components/logotype';
import CartIcon from '../common/components/cart.icon';
import debounce from 'lodash/debounce';
import { addClass, removeClass } from '../common/utils/dom.utils';
import { connect } from 'react-redux';
import { toggleLoginModal } from '../login/login.actions';
import connectWithTransitionGroup from '../../vendor/connect-with-transition-group';

const ShopHeader = React.createClass({

  _getInitialOrigin: function ( opt = '50% 50%' ) {
    let origin = opt;
    if ( opt === 'top' ) origin = 'top left';
    return origin;
  },

  _toggleLoginModal( e ) {
    this.props.loginModalToggle();
    //this.props.handleLoginModal( e );
  },

  _openLoginModal: function ( e ) {

    // example lodash defer, params passed to called function
    let defer = debounce( this._toggleLoginModal, 300, {
      'leading': true,
      'trailing': false
    });
    defer( e );
  },

  _handleTouchStart: function ( e ) {
    //@todo refactor to inline styling here, remove dependance on stylesheet?
    addClass( e.currentTarget, 'active' );
  },

  _handleTouchEnd: function ( e ) {
    //@todo ditto handleTouchStart
    removeClass( e.currentTarget, 'active' );
  },

  _animateIn: function ( done ) {
    let el = ReactDOM.findDOMNode( this );

    if ( this.props.pos ) {
      TweenLite.set( el, { 
        x: this.props.pos.touchX,
        y: this.props.pos.touchY
      })
    }

    TweenLite.set( el, { 
      opacity: 0,
      y: -50, 
      transformOrigin: this._getInitialOrigin()
    });
    TweenLite.to( el, 0.350, { 
      opacity: 1,
      y: 0,
      ease: Power2.easeInOut,
      onComplete: done
     });
  },

  _animateOut: function ( done ) {
    let el = ReactDOM.findDOMNode( this );
    TweenLite.to( el, 0.350, { 
      opacity: 0,
      x: -50, 
      transformOrigin: this._getInitialOrigin(),
      onComplete: done
     });
  },

  componentWillAppear: function ( done ) {
    this._animateIn( done );
  },

  componentWillEnter: function ( done ) {
    console.log('entering now...');
    this._animateIn( done );
  },

  componentWillLeave: function ( done ) {
    console.log('leaving now...');
    this._animateOut( done );
  },

  render: function() {
    return (
      <header id="home-header">
        <div id="home-header-content" className="Grid Grid-fit">
          <section onTouchStart={this._handleTouchStart} onTouchEnd={this._handleTouchEnd} id="home-header-logo" className="Grid-cell">
            <div className="home-header-item-container logo">
              <LogoType svgId="home-header-logotype"/>
            </div>
          </section>
          <section id="home-header-separator" className="Grid-cell">
          </section>
          <section onTouchStart={this._handleTouchStart} onTouchEnd={this._handleTouchEnd} onClick={this._openLoginModal} id="home-header-account" className="Grid-cell">
            <div className="home-header-item-container">
              <UserIcon svgId="home-header-account-icon"/>
              <span className="home-header-icon-label login">LogIn</span>
            </div>
          </section>
          <section onTouchStart={this._handleTouchStart} onTouchEnd={this._handleTouchEnd} id="home-header-cart" className="Grid-cell">
            <div className="home-header-item-container">
              <CartIcon svgId="home-header-cart-icon"/>
              <span className="home-header-icon-label cart">Cart</span>
            </div>
          </section>
        </div>
      </header>
    );
  }
});

// redux convenience function, map redux global actions to local props
const mapDispatchToProps = ( dispatch ) => {
  return {
    loginModalToggle: () => dispatch( toggleLoginModal() )
  };
};

// redux function to attach mapStateToProps and mapDispatchToProps to Dolls module
export default connectWithTransitionGroup( connect(
  null,
  mapDispatchToProps,
  null,
  { withRef: true }
)( ShopHeader ) );
