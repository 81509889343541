import { createActions } from 'redux-actions';
//import LocalApi from './local.api';
import UserService from '../common/services/user.service';

// regeneratorRuntime required for babel transpiling of async/await
import regeneratorRuntime from 'regenerator-runtime';

/**
 * { destructued } actionTypes correspond to actionCreators
 * createActions could be named createActionCreators
 * works in tandem with handleActions in settings.reducers.js
 * 
 * @param {object} actions - collection of combined action type definitions
 * and methods
 */
const { loadUser } = createActions({
  LOAD_USER: async () => await UserService.getUser()
});

export {
  loadUser
};