import React from 'react';

const EyeIcon = React.createClass({
  
  render: function() {
    let { svgId } = this.props;
    return (   
      <svg viewBox="-20 50 120 368" preserveAspectRatio="xMinYMin meet">
        <g id={ svgId }>
          <title>Eye</title>
          <description>Visual representation of a human eye</description>
          <path d="M160,182.58c-44.49,0-72.55,44.71-72.55,44.71S115.51,272,160,272s72.55-44.71,72.55-44.71S204.49,182.58,160,182.58z
             M146.85,204.93c5.04,0,8.99,4.16,8.99,9.21c0,5.04-4.16,9.21-8.99,9.21c-4.82,0-8.99-4.16-8.99-9.21
            C137.86,209.1,141.81,204.93,146.85,204.93z M160,260.38c-32.88,0-57.42-33.1-57.42-33.1s12.27-16.44,31.12-26.08
            c-3.73,5.48-5.92,11.84-5.92,19.07c0,18.63,14.9,33.53,33.31,33.53c18.41,0,33.32-15.12,33.32-33.53c0-6.36-1.75-12.27-4.82-17.31
            c17.1,9.86,28.06,24.33,28.06,24.33S192.88,260.38,160,260.38z"/>
        </g>
      </svg>
    );
  }

});

export default EyeIcon;