import React from 'react';

const H1 = ({ text }) => {
  return (
    <div className="row">
      <section className="header">
        <h1>{text}</h1>
      </section>
    </div>
  );
};

export default H1;