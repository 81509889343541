import React from 'react';
import classNames from 'classnames';

const ListItem = ({ idx, item, active, clicked }) => {

  const handleClick = e => clicked( item, idx );

  // img relative to root
  // http://stackoverflow.com/questions/37644265/correct-path-for-img-on-react-js
  let source = `/${item.imageUrl}`;

  let progress = { height: `${item.wizard.progress}%` };
  let containerClasses = classNames({
    'doll-container': true,
    active: idx === active
  });

  return (
    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-1">
      <div className="doll-progress-container">
        <div className="doll-progress">
          <div className="meter" style={progress}></div>
        </div>
      </div>
      <div onClick={handleClick} className={containerClasses}>
        <div className="doll">
          <img className="thumb-sm" src={source} />
          <div className="info">
            <h5 className="no-margin">{item.name}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  idx: React.PropTypes.number.isRequired,
  item: React.PropTypes.object.isRequired,
  clicked: React.PropTypes.func.isRequired
};

export default ListItem;