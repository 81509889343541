import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { isNil } from 'mightty';
import { updateProgress } from '../order/order.actions';
import ChooseColors from './step.choose.colors.js';

const Customizer = ({ params, location, dolls, changeRoute, update }) => {

  // state passed via openRoute in shop.index
  console.log('location state: ', location.state);

  const ChildInfo = () => (
    <div>Child Info</div>
  );
  const DefaultOutfit = () => (
    <div>DefaultOutfit</div>
  );

  const addProgress = () => {
    let newDoll = Object.assign( {}, dolls[1] );
    newDoll.wizard.progress = 33;
    newDoll.wizard.title = 'Default Outfit';
    newDoll.wizard.step = 3;
    update( 1, newDoll );
  };

  let steps = new Map();
  steps.set(1, ChooseColors);
  steps.set(2, ChildInfo);
  steps.set(3, DefaultOutfit);
  let doll = dolls[params.id];
  let SelectedStep = steps.get(doll.wizard.step);
  return (
    <div>
      <SelectedStep />
      <div className="row pad-5 gutter-10 bottom-row-pad">
        <div className="btn btn-primary btn-lg" role="button" onClick={addProgress}>Add Progress</div>
      </div>
    </div>
  );
};

const mapStateToProps = ( state ) => {
  console.log( 'tell me about the state now: ', state );
  return {
    dolls: state.order.dolls.items
  };
};

const mapDispatchToProps = ( dispatch ) => {
  return {
    update: ( idx, doll ) => dispatch( updateProgress( idx, doll ) ),
    changeRoute: ( url ) => dispatch( push( url ) )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( Customizer );