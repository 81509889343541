import { createActions } from 'redux-actions';
import LocalApi from '../local/local.api.js';

// required for async methods
let regeneratorRuntime = require('regenerator-runtime');

const { loadDolls, loadDollsSuccess, loadDollsError  } = createActions({
  LOAD_DOLLS: () => ({}),
  LOAD_DOLLS_SUCCESS: ( items ) => ({ items }),
  LOAD_DOLLS_ERROR: ( err ) => ({ error:err })
}); 

// dispatch multiple actions without redux-thunk
// we pass dispatch to goGetDolls in dolls.index.js
const goGetDolls = async ( dispatch ) => {
  dispatch( loadDolls() )
  dispatch( loadDollsSuccess( await LocalApi.getDolls() ) );
}

export {
  goGetDolls,
  loadDollsSuccess,
  loadDollsError
};