import React from 'react';
import { connect } from 'react-redux';
import CloseIcon from './close.icon';
import EyeIcon from './eye.icon';
import { 
  addClass, 
  removeClass 
} from '../utils/dom.utils';
import { 
  userDispatch, 
  toggleLoginModal 
} from '../../login/login.actions';
import LoginModalForm from './login.modal.form';
import { 
  submit,
  isInvalid,
  isSubmitting,
  hasSubmitFailed 
} from 'redux-form';
import connectWithTransitionGroup from '../../../vendor/connect-with-transition-group';

const LoginModal = React.createClass({

  getInitialState: function() { 
    return {
      panels: [
        {
          name: 'login',
          btnText: 'Log In',
          h1: 'Welcome Back'
        },
        {
          name: 'join', 
          btnText: 'Continue',
          h1: 'Create Account'
        }
      ],
      activeSwitcher: 'login'
    };
  },

  inputs: [],

  _fadeOutIn: function () {
    let inputs = document.getElementById('app-login-modal-body');
    let tl = new TimelineMax();
    tl.to( inputs , 0.350, {
        opacity: 0
      })
      .to( inputs , 0.350, {
        opacity: 1
      }, "-=0.175");
  },

  _handleSwitcherClick: function ( e ) {
    e.preventDefault();
    this._fadeOutIn();
    if ( e.currentTarget.textContent === 'Join' && this.state.activeSwitcher === 'login' ) {
      removeClass( this.loginLink, 'active' );
      this.setState({
        activeSwitcher: 'join'
      });
    } else if ( e.currentTarget.textContent === 'Login' && this.state.activeSwitcher === 'join' ) {
      removeClass( this.joinLink, 'active' );
      this.setState({
        activeSwitcher: 'login'
      });
    }
    addClass( e.currentTarget, 'active' );
  },

  _getInputs( el ) {
    this.inputs.push( el );
    console.log('show me dem inputs', this.inputs);
  },

  _getPasswordInput() {
    // filter input array by input name
    this.passwordInput = this.inputs.filter((item) => item.name === 'password' );
  },

  _getJoinLink: function ( el ) {
    this.joinLink = el;
  },

  _getLoginLink: function ( el ) {
    this.loginLink = el;
  },

  _getInitialOrigin: function ( opt = '50% 50%' ) {
    let origin = opt;
    if ( opt === 'top' ) origin = 'top left';
    return origin;
  },

  _submitLoginForm() {
    this.props.submitForm('LoginModalForm');
  },

  _handleSubmit( { email, password } ) {
    if ( this.state.activeSwitcher === 'login' ) {
      return this.props.login( email, password );
    } else {
      return this.props.register( email, password );
    }
  },

  _closeLoginModal: function ( e ) {
    console.log('close LOGIN MODAL PLEASE', e.target.getAttribute('id') === 'app-modal-overlay' );
    // only fire event if overlay was clicked directly, don't bubble
    if ( e.target.getAttribute('id') === 'app-modal-overlay' 
      || e.currentTarget.id === 'app-modal-login-cancel' 
      || e.currentTarget.id === 'app-modal-login-cancel-btn' ) {
      //this.props.handleLoginModal( e, this.props.stateKey );
      this.props.loginModalToggle();
    } else {
      e.stopPropagation();
    }
  },

  _getModal: function ( el ) {
    return this.modal = el;
  },

  _getOverlay: function ( el ) {
    return this.overlay = el;
  },

  _getBgTexture: function ( el ) {
    return this.bgTexture = el;
  },

  _animateIn: function ( done ) {
    let overlay = this.overlay;
    let modal = this.modal;

    if ( this.props.pos ) {
      TweenLite.set( el, { 
        x: this.props.pos.touchX,
        y: this.props.pos.touchY
      })
    }
    TweenLite.set( modal, { 
      x: 500, 
      transformOrigin: this._getInitialOrigin()
    });
    TweenLite.set( overlay, { 
      opacity: 0,
      transformOrigin: this._getInitialOrigin()
    });
    TweenLite.to( overlay, 0.350, { 
      opacity: 1,
      backgroundColor: 'rgba(68,68,68,.8)'
     });
    TweenLite.to( modal, 0.350, { 
      x: 0,
      ease: Power2.easeInOut,
      onComplete: done
     });
  },

  _animateOut: function ( done ) {
    let overlay = this.overlay;
    let modal = this.modal;

    let tl = new TimelineMax();
    tl.to( modal, 0.350, { 
      x: -500,
      ease: Power2.easeInOut
     })  
    .to( overlay, 0.350, { 
      opacity: 0,
      backgroundColor: 'rgba(255,255,255,0)',
      onComplete: done
     });
  },

  componentWillAppear: function ( done ) {
    this._animateIn( done );
  },

  componentWillEnter: function ( done ) {
    console.log('entering now...');
    this._animateIn( done );
  },

  componentWillLeave: function ( done ) {
    console.log('leaving now...');
    this._animateOut( done );
  },

  componentDidMount: function () {
    addClass( this.loginLink, 'active' );
  },

  render: function() {
    let panel = this.state.panels.filter( item  => item.name === this.state.activeSwitcher )[0];
    return (
      <div>
        <div className="app-overlay login-modal-container">
          <div ref={this._getOverlay} onClick={this._closeLoginModal} id="app-modal-overlay" key="app-overlay-modal-bg-01" className="login-modal Grid Grid-fit">           
            <div ref={this._getModal} className="Grid-cell login-modal-form" noValidate>
              <section className="app-modal-header">
                <i onClick={this._closeLoginModal} className="app-modal-cancel" id="app-modal-login-cancel">
                  <CloseIcon svgId="login-modal-close-icon"/>
                </i>
                <h1 className="centered">{panel.h1}</h1>
                <div className="panel-selector">
                  <ul className="Grid Grid Grid-1of2">
                    <li className="Grid-cell" ref={ this._getJoinLink } onClick={this._handleSwitcherClick}>Join</li>
                    <li className="Grid-cell" ref={ this._getLoginLink } onClick={this._handleSwitcherClick}>Login</li>
                  </ul>
                </div>
              </section> 
              <LoginModalForm onSubmit={this._handleSubmit} activeSwitcher={this.state.activeSwitcher} />
              <div className="modal-footer Grid Grid-fit">
                <div className="Grid-cell">
                  <button id="app-modal-login-cancel-btn" className="btn btn-doll-customize-shape cancel" onClick={this._closeLoginModal}>Cancel</button>
                </div>
                <div className="Grid-cell app-modal-login-btn">
                  <button disabled={ this.props.submitting || this.props.invalid && this.props.submitFailed } onClick={this._submitLoginForm} className="btn btn-doll-customize-shape">{panel.btnText}</button>
                </div>
                <div className="Grid-cell Grid-break"></div> 
                <div className="Grid-cell join-us">  
                  <h3>New around here? <span className="bold-text">Join us</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={this._getBgTexture} key="app-overlay-bg-01" id="app-bg-texture" />
      </div>
    );
  }
});

// redux convenience function, map redux global state to local props
const mapStateToProps = ( state ) => {
  console.log( 'tell me about the state now: ', state );
  return {
    response: state.login.response,
    fetching: state.login.fetching,
    invalid: isInvalid( 'LoginModalForm' )( state ),
    submitting: isSubmitting( 'LoginModalForm' )( state ),
    submitFailed: hasSubmitFailed( 'LoginModalForm' )( state )
  };
};

// redux convenience function, map redux global actions to local props
const mapDispatchToProps = ( dispatch ) => {
  return {
    submitForm: ( formName ) => dispatch( submit( formName ) ),
    login: ( email, password ) => dispatch( userDispatch( 'login', email, password ) ),
    loginModalToggle: () => dispatch( toggleLoginModal() ),
    register: ( email, password ) => dispatch( userDispatch( 'register', email, password ) )
  };
};

// redux function to attach mapStateToProps and mapDispatchToProps to Dolls module
export default connectWithTransitionGroup( connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true }
)( LoginModal ) );
